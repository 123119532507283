<template>
  <div style="position: absolute;width: 100%;height: 100%;background: linear-gradient(122deg, rgb(83, 73, 248) 0%, rgb(85, 125, 252) 100%);;">
    <div class="app1Img">
      <img style="width: 7.47rem;height: 3.68rem"
           src="../../assets/images/app1.png">
    </div>
    <div class="app2Img">
      <img style="width: 1.93rem;height: 1.95rem"
           src="../../assets/images/app2.png">
    </div>
    <div class="app3Img">
      <img style="width: 3.07rem;height: 1.29rem"
           src="../../assets/images/app3.png">
    </div>
    <div class="app4Img">
      <img style="width: 9.88rem;height: 5.36rem"
           src="../../assets/images/app4.png">
    </div>
    <div class="app5Img">
      <img style="width: 7.8rem;height: 7.03rem"
           src="../../assets/images/app5.png">
    </div>

    <div class="textBox">
      <div style="font-size: 0.56rem;font-weight: 800;color: #FFFFFF">继续教育小程序上线了！</div>
      <div style="font-size: 0.2rem;margin-top: 0.5rem;opacity: 0.9;line-height: 0.3rem">继续教育小程序用于专业人员继续教育网上学习，实现在线选课、购课、学习及<br>
        会员管理功能；目前在小程序实现选课、购课、学习及配套功能，主要打造在线<br>
        教育电子商务和视频播放专业平台。</div>
      <div style="display: flex;margin-top: 0.4rem;font-size: 0.22rem">
        <div style="cursor: pointer;text-align: center;color: #515CF2;height: 0.6rem;width: 1.6rem;background: #FFFFFF;border-radius: 0.1rem;line-height: 0.6rem;margin-right: 0.2rem">在线学习</div>
        <div style="cursor: pointer;text-align: center;color: #515CF2;height: 0.6rem;width: 1.6rem;background: #FFFFFF;border-radius: 0.1rem;line-height: 0.6rem;margin-right: 0.2rem">考试做题</div>
        <div style="cursor: pointer;text-align: center;color: #515CF2;height: 0.6rem;width: 1.6rem;background: #FFFFFF;border-radius: 0.1rem;line-height: 0.6rem">查看证书</div>
      </div>

      <div style="display: flex">
        <div style="text-align: center;width: 2.2rem;margin-top: 0.6rem;font-size: 0.2rem">
          <div style="width: 2.2rem;height: 2.2rem;margin-bottom: 0.25rem;display: flex;justify-content: center;align-items: center">
            <img style="width: 2rem;height: 2rem;"
                 src="../../assets/images/wetApp.png"
                 alt="">
          </div>
          扫码查看小程序
        </div>

        <div style="text-align: center;width: 2.2rem;margin-top: 0.6rem;font-size: 0.2rem;margin-left: 0.6rem">
          <div style="width: 2.2rem;height: 2.2rem;margin-bottom: 0.25rem;display: flex;justify-content: center;align-items: center">
            <img style="width: 2rem;height: 2rem;"
                 src="../../assets/images/yidongIcon.png"
                 alt="">
          </div>
          扫码查看移动端
        </div>
      </div>

    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {};
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var designSize = 1920; // 设计图尺寸

    var html = document.documentElement;

    var wW = html.clientWidth; // 窗口宽度

    var rem = (wW * 100) / designSize;

    document.documentElement.style.fontSize = rem + 'px';
    window.addEventListener('resize', function () {
      var designSize = 1920; // 设计图尺寸

      var html = document.documentElement;

      var wW = html.clientWidth; // 窗口宽度

      var rem = (wW * 100) / designSize;

      document.documentElement.style.fontSize = rem + 'px';
    });
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.app1Img {
  position: absolute;
  top: 0;
  left: 0;
}
.app2Img {
  position: absolute;
  top: 0;
  right: 0;
}
.app3Img {
  position: absolute;
  bottom: 0;
  left: 0;
}
.app4Img {
  position: absolute;
  bottom: 0;
  left: 2.45rem;
}
.app5Img {
  position: absolute;
  top: 1.17rem;
  right: 1.71rem;
}
.textBox {
  position: absolute;
  top: 1.8rem;
  left: 2rem;
  color: #ffffff;
}
</style>